import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker, CalloutMonthPicker, DateRangeTypeSelector, MonthNavigationArrow, TotalBookedHours, CountryPicker, ProjectGroupsList, DroppableProjectGroupsListComponent, Divider, VerticalDivider, ProjectDashboardComponent } from "../"
import { setIsMaintenance, useAppDispatch, useAppSelector, useGetMaintenanceStatusQuery, useAddProjectToGroupMutation, useRemoveProjectFromGroupMutation, updateProjectGroup } from "../../Redux";
import FailedTimeEntriesPanel from '../TimeEntry/FailedTimeEntriesPanelComponent';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, MouseSensor, pointerWithin, useSensor, useSensors } from "@dnd-kit/core";
import DraggableProjectRow from "../Projects/DraggableProjectRow";
import { IUserProject } from "../../Models";
import { UngroupProjectContainer } from "./UngroupProjectContainer";

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: maintenanceStatusResult } = useGetMaintenanceStatusQuery();
  const [addProjectToGroup, { isSuccess: addToGroupSuccess }] = useAddProjectToGroupMutation();
  const [removeProjectFromGroup, { isSuccess: removeFromGroupSuccess }] = useRemoveProjectFromGroupMutation();
  const userProfile = useAppSelector((state) => state.userProfile.userProfile);
  const projectsList = useAppSelector((state) => state.projects.projectsList);
  const projectGroups = useAppSelector((state) => state.projectGroups.projectGroupsList);

  const [isDragging, setIsDragging] = useState(false);
  const [draggedProject, setDraggedProject] = useState<IUserProject>();
  const [overGroupId, setOverGroupId] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
  );

  useEffect(() => {
    if (addToGroupSuccess || removeFromGroupSuccess) {
      dispatch(updateProjectGroup({ id: draggedProject?.id as number, groupId: overGroupId }));
      setDraggedProject(undefined);
      setOverGroupId(null);
    }
  }, [addToGroupSuccess, removeFromGroupSuccess]);

  useEffect(() => {
    if (maintenanceStatusResult && maintenanceStatusResult.isMaintenance) {
      let isAdmin = userProfile && userProfile.isAdmin;
      if (!isAdmin) {
        dispatch(setIsMaintenance(true));
        navigate('/maintenance')
      }
    }
  }, [maintenanceStatusResult]);


  const onDragStart = function (ev: DragStartEvent) {
    if (ev.active && ev.active.id) {
      setIsDragging(true);
      setDraggedProject(projectsList?.filter(p => p.id == ev.active.id)[0]);
    }
  }

  const handleDragEnd = function (ev: DragEndEvent) {
    const { active, over } = ev;
    if (active.id && over?.id) {
      if (over.id == 'ungroup') {
        removeProjectFromGroup({ projectId: active.id });
        setOverGroupId(null);
      }
      else {
        let overId = Number(over.id.toString().split('&')[0]);
        addProjectToGroup({ groupId: overId, projectId: active.id })
        setOverGroupId(overId);
      }
    }

    setIsDragging(false);
  }

  const handleCancel = () => {
    setIsDragging(false);
    setDraggedProject(undefined);
  }

  return (<>
    <DndContext sensors={sensors} collisionDetection={pointerWithin} onDragStart={onDragStart} onDragEnd={handleDragEnd} onDragCancel={handleCancel}>
      <div className='flex flex-col space-y-4 items-stretch lg:flex-row lg:space-x-2 flex-nowrap mb-8'>
        
        {!isDragging &&
          <div className='hidden flex-1 lg:flex flex-col flex-grow xl:flex-grow-0'>
            <CountryPicker />
            <DatePicker />
            <DateRangeTypeSelector />
            <TotalBookedHours />
            <Divider text="Project Groups" />
            <ProjectGroupsList />
          </div>
        }
        {isDragging &&
          <div className='flex sticky top-5 w-[220px]'>
            <DroppableProjectGroupsListComponent />
          </div>
        }
        <div className="hidden lg:flex">
          <VerticalDivider />
        </div>
        <div className='max-w-full lg:max-w-[90%] flex-auto'>
          <FailedTimeEntriesPanel />
          <ProjectDashboardComponent />
          {isDragging && projectGroups && projectGroups.length > 0 && <UngroupProjectContainer />}
        </div>

        {isDragging && draggedProject &&
          <DragOverlay>
            <DraggableProjectRow project={draggedProject} isDragging />
          </DragOverlay>
        }
      </div>
    </DndContext>
  </>
  );
}