import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IDailyBookedTime, ITimeEntry } from '../Models/ITimeEntry';

interface ITimeEntriesState {
    currentSelectedTimeEntry?: ITimeEntry,
    showTimeEntryCopyPanel: boolean,
    timeEntriesToBeDeleted: {
        workPackageId?: number,
        timeEntries: string[]
    }
}

const initialState: ITimeEntriesState = {
    currentSelectedTimeEntry: undefined,
    showTimeEntryCopyPanel: false,
    timeEntriesToBeDeleted: {
        timeEntries: []
    }
}

export const timeEntriesSlice = createSlice({
    name: 'timeEntries',
    initialState,
    reducers: {
        switchShowTimeEntryCopyPanel: (state) => {
            state.currentSelectedTimeEntry = undefined;
            state.showTimeEntryCopyPanel = !state.showTimeEntryCopyPanel;
        },
        openTimeEntryCopyPanel: (state, action: PayloadAction<ITimeEntry>) => {
            state.currentSelectedTimeEntry = action.payload;
            state.showTimeEntryCopyPanel = true;
        },
        addTimeEntryToDelete: (state, action: PayloadAction<{ workPackageId: number, timeEntryId: string }>) => {
            if (state.timeEntriesToBeDeleted.workPackageId) {
                if (action.payload.workPackageId == state.timeEntriesToBeDeleted.workPackageId) {
                    state.timeEntriesToBeDeleted.timeEntries.push(action.payload.timeEntryId);
                }
            } else {
                state.timeEntriesToBeDeleted.workPackageId = action.payload.workPackageId
                state.timeEntriesToBeDeleted.timeEntries.push(action.payload.timeEntryId);
            }
        },
        removeTimeEntryToDelete: (state, action: PayloadAction<{ workPackageId: number, timeEntryId: string }>) => {
            if (state.timeEntriesToBeDeleted.workPackageId && action.payload.workPackageId == state.timeEntriesToBeDeleted.workPackageId) {
                state.timeEntriesToBeDeleted.timeEntries = state.timeEntriesToBeDeleted.timeEntries.filter(t => t != action.payload.timeEntryId);
                if (state.timeEntriesToBeDeleted.timeEntries.length == 0) {
                    state.timeEntriesToBeDeleted.workPackageId = undefined;
                }
            }
        },
        emptyTimeEntriesToBeDeleted: (state) => {
            state.timeEntriesToBeDeleted = { timeEntries: [] };
        }
    }
});

export const timeRecordsApi = createApi({
    reducerPath: 'timerecord_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/timerecordings`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    tagTypes: ['TimeRecords', 'FaultyTimeRecords', 'TotalBooked'],
    endpoints(builder) {
        return {
            fetchTimeRecord: builder.query<ITimeEntry, { id: string }>({
                query(params) {
                    return `/GetTimeRecording?id=${params.id}`;
                }
            }),
            waitForTimeRecordingStateUpdate: builder.query<number, { id: string }>({
                query(params) {
                    return `/WaitForTimeRecordingStateUpdate?id=${params.id}`;
                }
            }),
            fetchTimeRecords: builder.query<ITimeEntry[], { dateRange: Date[], workPackageId: number }>({
                query(params) {
                    var startDate = params.dateRange[0].toDateString();
                    var endDate = params.dateRange[1].toDateString();
                    return `/GetUserTimeRecordings?from=${startDate}&until=${endDate}&workingPackageId=${params.workPackageId}`;
                },
                providesTags: ['TimeRecords']
            }),
            fetchFaultyTimeRecords: builder.query<ITimeEntry[], void>({
                query() {
                    return '/GetFaultyTimeRecordingForUser';
                },
                providesTags: ['FaultyTimeRecords']
            }),
            fetchTotalBookedOnProject: builder.query<number, { dateRange: Date[], workPackageId: number }>({
                query(params) {
                    return `/GetTotalBookedByDatesAndWorkPackage?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}&workPackageId=${params.workPackageId}`;
                },
                providesTags: ['TotalBooked']
            }),
            fetchTotalBookedForDateRange: builder.query<number, { dateRange: Date[] }>({
                query(params) {
                    return `/GetTotalTimeBookedForTimePeriod?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}`;
                },
                providesTags: ['TotalBooked']
            }),
            fetchTotalBookedHoursPerDay: builder.query<IDailyBookedTime[], { dateRange: Date[] }>({
                query(params) {
                    return `/GetTotalBookedHoursForTimeRange?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}`;
                }
            }),
            fetchXlsxTimeRecords: builder.query<any, { dateRange: Date[], projectIds?: number[] }>({
                query(params) {
                    var projectsString = "";
                    if (params.projectIds) {
                        if (params.projectIds.length > 0) {
                            params.projectIds?.forEach(function (value, index) {
                                projectsString += "&projects[" + index + "]=" + value;
                            });
                        }
                    }

                    return `/GetUserTimeRecordingsAsXLS?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}${projectsString}`
                },
                keepUnusedDataFor: 10
            }),
            fetchCsvTimeRecords: builder.query<any, { dateRange: Date[], projectIds?: number[] }>({
                query(params) {
                    var projectsString = "";
                    if (params.projectIds) {
                        if (params.projectIds.length > 0) {
                            params.projectIds?.forEach(function (value, index) {
                                projectsString += "&projects[" + index + "]=" + value;
                            });
                        }
                    }

                    return `/GetUserTimeRecordingsAsCSV?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}${projectsString}`
                },
                keepUnusedDataFor: 10
            }),
            fetchPdfTimeRecords: builder.query<any, { dateRange: Date[], projectIds?: number[] }>({
                query(params) {
                    var projectsString = "";
                    if (params.projectIds) {
                        if (params.projectIds.length > 0) {
                            params.projectIds?.forEach(function (value, index) {
                                projectsString += "&projects[" + index + "]=" + value;
                            });
                        }
                    }

                    return `/GetUserTimeRecordingsAsPDF?from=${params.dateRange[0].toDateString()}&until=${params.dateRange[1].toDateString()}${projectsString}`
                },
                keepUnusedDataFor: 10
            }),
            postTimeRecord: builder.mutation({
                query: timeRecord => ({
                    url: '/PostTimeRecording',
                    method: 'POST',
                    body: timeRecord
                }),
                invalidatesTags: ['TimeRecords', 'TotalBooked']
            }),
            putTimeRecord: builder.mutation({
                query: timeRecord => ({
                    url: `/PutTimeRecording?id=${timeRecord.id}`,
                    method: 'PUT',
                    body: timeRecord
                }),
                invalidatesTags: ['TimeRecords', 'TotalBooked', 'FaultyTimeRecords']
            }),
            resendTimeRecord: builder.mutation({
                query: timeRecord => ({
                    url: `/ResendTimeRecording`,
                    method: 'PUT',
                    body: timeRecord
                }),
                invalidatesTags: ['TotalBooked', 'FaultyTimeRecords', 'FaultyTimeRecords']
            }),
            revertTimeRecord: builder.mutation({
                query: xrmTimeRecordId => ({
                    url: `/RevertTimeRecording?xrmId=${xrmTimeRecordId}`,
                    method: 'POST'
                }),
                invalidatesTags: ['TimeRecords', 'TotalBooked', 'FaultyTimeRecords', 'FaultyTimeRecords']
            }),
            copyTimeRecord: builder.mutation({
                query: args => ({
                    url: `/CopyTimeRecording?timeRecordingId=${args.id}`,
                    method: 'POST',
                    body: args.selectedDates
                }),
                invalidatesTags: ['TimeRecords', 'TotalBooked']
            }),
            deleteTimeRecord: builder.mutation({
                query: timeRecordId => ({
                    url: `/DeleteTimeRecording?id=${timeRecordId}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['TimeRecords', 'FaultyTimeRecords', 'TotalBooked']
            }),
            deleteTimeRecords: builder.mutation({
                query: timeRecordIds => ({
                    url: `/DeleteTimeRecordings`,
                    method: 'DELETE',
                    body: timeRecordIds
                }),
                invalidatesTags: ['TimeRecords', 'FaultyTimeRecords', 'TotalBooked']
            })
        }
    }
});

export const { useLazyFetchTimeRecordQuery,
    useLazyWaitForTimeRecordingStateUpdateQuery,
    useFetchTimeRecordsQuery,
    useLazyFetchTimeRecordsQuery,
    useFetchFaultyTimeRecordsQuery,
    useFetchTotalBookedForDateRangeQuery,
    useLazyFetchTotalBookedHoursPerDayQuery,
    useLazyFetchTotalBookedForDateRangeQuery,
    useLazyFetchFaultyTimeRecordsQuery,
    useFetchTotalBookedOnProjectQuery,
    useLazyFetchXlsxTimeRecordsQuery,
    useLazyFetchCsvTimeRecordsQuery,
    useLazyFetchPdfTimeRecordsQuery,
    usePostTimeRecordMutation,
    useCopyTimeRecordMutation,
    usePutTimeRecordMutation,
    useResendTimeRecordMutation,
    useRevertTimeRecordMutation,
    useDeleteTimeRecordMutation,
    useDeleteTimeRecordsMutation } = timeRecordsApi;


export const { openTimeEntryCopyPanel, switchShowTimeEntryCopyPanel, addTimeEntryToDelete, removeTimeEntryToDelete, emptyTimeEntriesToBeDeleted } = timeEntriesSlice.actions;

export default timeEntriesSlice.reducer;