import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { loadTheme } from '@fluentui/react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./Auth/authConfig";
import { setLocale, setTranslations } from 'react-i18nify';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { translations } from "./i18n/translations"
import {
  RibbonBar, Dashboard, NoUserProfile, DownloadTimeEntries, ActivityTemplatesContainer, Footer, Maintenance, ProjectEditPanel, MaintenanceUpdatePanel,
  UnexpectedError, ChangelogPage, GlobalNotifications, NotificationMessages, FunOverlay, UserSettingsPanelComponent, ActivityTemplateModalComponent,
  MonthNavigationArrow,
  CalloutMonthPicker,
  TotalBookedHours
} from './Components'
import { useAppSelector } from './Redux';
import ScrollToTop from './Utils/ScrollToTop';
import { Navigation } from './Components/Navigation/NavigationComponent';
import TimeEntryCopyPanelComponent from './Components/TimeEntry/TimeEntryCopyPanelComponent';

/* translations */
setTranslations(translations);

/* fluent UI */
initializeIcons();
loadTheme({
  palette: {
    themePrimary: '#1f004c',
    themeLighterAlt: '#d9ceea',
    themeLighter: '#b8a5d6',
    themeLight: '#9b80c2',
    themeTertiary: '#8060af',
    themeSecondary: '#67449b',
    themeDarkAlt: '#512d87',
    themeDark: '#3e1974',
    themeDarker: '#2d0b60',
    neutralLighterAlt: '#eeeeee',
    neutralLighter: '#eaeaea',
    neutralLight: '#e1e1e1',
    neutralQuaternaryAlt: '#d1d1d1',
    neutralQuaternary: '#c8c8c8',
    neutralTertiaryAlt: '#c0c0c0',
    neutralTertiary: '#b3b3b3',
    neutralSecondary: '#9a9a9a',
    neutralPrimaryAlt: '#818181',
    neutralPrimary: '#1e1e1e',
    neutralDark: '#505050',
    black: '#373737',
    white: '#ffffff',
  }
});

function App() {
  setLocale(`Lang_1033`, false);
  const { instance, accounts } = useMsal();
  const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
  const hasGlobalnotification: boolean = useAppSelector(s => s.administration.hasGlobalnotification);
  const showLoading: boolean = useAppSelector((state) => state.loading.isLoading);

  var isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) {
    instance.loginRedirect(loginRequest).catch(e => console.error(e));
  }

  return (
    <Router basename='/' >
      <FunOverlay></FunOverlay>
      <ScrollToTop></ScrollToTop>
      <div className='overflow-x-hidden bg-neutral w-full min-h-screen cursor-fx-target'>
        {
          showLoading &&
          <div className='w-full m-0 p-0 fixed left-0 top-0 h-[2px] z-[1300] overflow-hidden'>
            <div className='absolute bg-primary w-full h-[2px]'></div>
            <div className='absolute h-[2px] bg-gradient-to-r from-primary via-tertiary to-primary w-[30%] transition-[width] animate-progress-bar'></div>
          </div>
        }
        <RibbonBar />
        <Navigation />
        <div className={`${isNavigationPanelOpen ? 'lg:pl-64' : 'lg:pl-16'} top-20 left-0 w-full fixed z-20`}>
          <GlobalNotifications />
          <div className={`bg-neutral fixed shadow-lg w-full h-18 lg:invisible py-1`}>
            <div className="mx-auto flex flex-row justify-center space-x-20 lg:hidden flex-wrap">
              <MonthNavigationArrow past />
              <CalloutMonthPicker />
              <MonthNavigationArrow />
            </div>
            <div className="flex lg:hidden w-full text-center justify-center">
              <TotalBookedHours />
            </div>
          </div>
        </div>
        <div className='top-24 z-[120] w-full absolute'>
          <NotificationMessages />
        </div>
        <div className={`${hasGlobalnotification ? 'mt-56 lg:mt-40' : 'mt-36'} mb-1 lg:mb-1 lg:mt-32 lg:pr-8 w-full flex flex-row items-start space-x-3 ${isNavigationPanelOpen ? 'lg:pl-72' : 'lg:pl-24'}`}>
          <Routes>
            <Route path='/dashboard' element={<>
              <div className='w-full z-10'>
                <Dashboard />
              </div>
            </>
            } />

            <Route path='/activityTemplates' element={<>
              <div className='w-full mb-2'>
                <ActivityTemplatesContainer />
              </div>
            </>} />

            <Route path='/download' element={<>
              <div className='w-full'>
                <DownloadTimeEntries />
              </div>
            </>} />

            <Route path='/nouserprofile' element={<>
              <div className='w-full'>
                <NoUserProfile userLogin={accounts[0]?.username} />
              </div>
            </>} />

            <Route path='/error' element={<>
              <div className='w-full'>
                <UnexpectedError />
              </div>
            </>} />

            <Route path='/changelog' element={<>
              <div className='w-full'>
                <ChangelogPage />
              </div>
            </>} />

            <Route path='/maintenance' element={<>
              <div className='w-full h-screen'>
                <Maintenance />
              </div>
            </>} />
          </Routes>
        </div>

        <ProjectEditPanel />
        <MaintenanceUpdatePanel />
        <UserSettingsPanelComponent />
        <ActivityTemplateModalComponent />
        <TimeEntryCopyPanelComponent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
