import { Icon } from "@fluentui/react"
import { useEffect, useState } from "react"
import { Translate } from "react-i18nify"

interface IProjectBillableType {
    typeText: string
}

export const ProjectBillableType = (props: IProjectBillableType) => {
    const { typeText } = { ...props };
    const [isTextVisible, setIsTextVisible] = useState<boolean>(false);


    return (<>
        <span className="relative flex flex-row h-7 w-full items-center text-xs font-semibold xl:justify-end" onTouchStart={() => setIsTextVisible(true)} onTouchEnd={() => setIsTextVisible(false)} onMouseOver={() => setIsTextVisible(true)} onMouseLeave={() => setIsTextVisible(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>

            <span className="font-bold"><Translate value='App_Label_Billing_Type' /></span>&nbsp;<span>{typeText}</span>
        </span>
        {
            isTextVisible && <div className="absolute xl:ml-20 -mt-24 bg-primary shadow-md h-auto w-80 z-[101] flex flex-col space-y-2 p-3 backdrop-blur-3xl border border-tertiary text-xs rounded-md">
                <p className="text-tertiary font-semibold">PV - Zeiterfassung in timetable und Planview</p>
                <p className="text-tertiary font-semibold">RE, Ariba & LM - Zeiterfassung in timetable</p>
                <p className="text-tertiary font-semibold">SNOW - Zeiterfassung in timetable und Service Now</p>
            </div>
        }
    </>)
}